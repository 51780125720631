import React from 'react';
import classList from 'react-classlist-helper';

import './Promo.scss';

//pre Sept 6th
import beetlejuice_promo_mob_alt from 'assets/images/campaign/beetlejuice-promo-mob--alt.png'
import beetlejuice_promo_mob2x_alt from 'assets/images/campaign/beetlejuice-promo-mob--alt@2x.png'
import beetlejuice_promo_desk_alt from 'assets/images/campaign/beetlejuice-promo-desk--alt.png'
import beetlejuice_promo_desk2x_alt from 'assets/images/campaign/beetlejuice-promo-desk--alt@2x.png'

//post Sept 6th

import beetlejuice_promo_mob from 'assets/images/campaign/beetlejuice-promo-mob.png'
import beetlejuice_promo_mob2x from 'assets/images/campaign/beetlejuice-promo-mob@2x.png'
import beetlejuice_promo_desk from 'assets/images/campaign/beetlejuice-promo-desk.png'
import beetlejuice_promo_desk2x from 'assets/images/campaign/beetlejuice-promo-desk@2x.png'

import beetlejuice_promo_gums_desk from 'assets/images/campaign/beetlejuice-promo-gums-desk.png'
import beetlejuice_promo_gums_desk2x from 'assets/images/campaign/beetlejuice-promo-gums-desk@2x.png'

import beetlejuice_promo_liquorice_desk from 'assets/images/campaign/beetlejuice-promo-liquorice-desk.png'
import beetlejuice_promo_liquorice_desk2x from 'assets/images/campaign/beetlejuice-promo-liquorice-desk@2x.png'

const Promo = (props) => {

  const classMap = {
    "promo": true,
    "promo--nobugs": !!props.nobugs,
    "promo--claim": !!props.claim,
    "promo--choice": !!props.choice
  };

  const currentDate = new Date();
  const targetDate = new Date('2024-09-05T23:59:59');

  return (
    <div className={classList(classMap)}>
        <div className="promo__wrapper">    

        {currentDate >= targetDate ? (
          <picture>    
          <source media="(min-width: 1080px)" srcSet={beetlejuice_promo_desk2x + " 2x, " + beetlejuice_promo_desk + " 1x"} type="image/png" />
          <img src={beetlejuice_promo_mob} srcSet={beetlejuice_promo_mob2x + " 2x, " + beetlejuice_promo_mob + " 1x"} alt="Only in cinemas" 
          className="promo__beetlejuice" />  
          </picture> 
        ) : (
          <picture>    
          <source media="(min-width: 1080px)" srcSet={beetlejuice_promo_desk2x_alt + " 2x, " + beetlejuice_promo_desk_alt + " 1x"} type="image/png" />
          <img src={beetlejuice_promo_mob_alt} srcSet={beetlejuice_promo_mob2x_alt + " 2x, " + beetlejuice_promo_mob_alt + " 1x"} alt="In cinemas september 6th" 
          className="promo__beetlejuice" />  
          </picture> 
        )}


            <picture>    
              <img src={beetlejuice_promo_gums_desk} srcSet={beetlejuice_promo_gums_desk2x + " 2x, " + beetlejuice_promo_gums_desk + " 1x"} alt=""
              className="promo__gums" />  
            </picture> 

            <picture>    
              <img src={beetlejuice_promo_liquorice_desk} srcSet={beetlejuice_promo_liquorice_desk2x + " 2x, " + beetlejuice_promo_liquorice_desk + " 1x"} alt="" 
              className="promo__liquorice" />  
            </picture> 
          </div>    
    </div>
  )
}

export default Promo;
